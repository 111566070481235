@import url(https://use.typekit.net/zsi5xde.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-header {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: fixed;
}

.logo {
  height: 60px;
}

.icon {
  display: none;
}

.landing-container {
  width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 900px) {
  .landing-header {
    height: 100px;
  }
  .landing-container {
    width: 700px;
  }
  .logo {
    display: initial;
    height: 50px;
  }
}

@media (max-width: 700px) {
  .landing-header {
    height: 250px;
  }
  .landing-container {
    justify-content: center;
  }
  .logo {
    display: none;
  }
  .icon {
    display: initial;
    height: 150px;
  }
  .landing-container {
    width: 90%;
  }
  #header-sign-in {
    display: none;
  }
}
/*# sourceMappingURL=LandingHeader.css.map */
.landing-main {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.landing-content {
  width: 900px;
  color: #666;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.landing-info {
  width: 400px;
  text-align: left;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  transition: .2s;
  -webkit-animation: flyInRight 1s ease-in-out;
          animation: flyInRight 1s ease-in-out;
}

.landing-info p {
  line-height: 1.5em;
  text-transform: initial;
}

.budget-preview {
  width: 380px;
  padding: 25px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  transition: .2s;
  -webkit-animation: flyInLeft 1s ease-in-out;
          animation: flyInLeft 1s ease-in-out;
}

.budget-preview h2 {
  margin-bottom: 20px;
  width: 320px;
  text-align: left;
}

.transaction {
  border: 1px solid #EE6352;
  height: 40px;
  width: 320px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 10px;
  margin: 10px 0;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}

.transaction span {
  font-size: .7em;
  font-weight: 700;
}

.trans-content {
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.trans-content span {
  font-size: 1.2em;
}

.landing-footer {
  height: 50px;
  width: 100%;
  background-color: #484D6D;
  position: absolute;
  bottom: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.landing-footer p {
  font-weight: 700;
  text-transform: initial;
  color: white;
}

.landing-footer img {
  height: 25px;
  margin: 0 10px;
}

@media (max-width: 900px) {
  .landing-info {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    height: 340px;
  }
  .landing-info h1 {
    font-size: 2em;
  }
  .budget-preview {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .landing-content {
    width: 700px;
  }
}

@media (max-width: 700px) {
  .landing-footer p {
    display: none;
  }
  .landing-main {
    flex-direction: column;
    justify-content: flex-start;
  }
  .landing-content {
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 0;
    margin-top: 350px;
  }
  .landing-info {
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 90%;
    height: 200px;
    align-items: center;
    margin: 0;
  }
  .landing-info h1 {
    text-align: center;
  }
  .landing-info p {
    text-align: center;
  }
  .budget-preview {
    display: none;
  }
  #sign-in {
    width: 300px;
  }
}
/*# sourceMappingURL=Landing.css.map */
.sign-in-header {
  width: 100%;
  position: fixed;
}

.sign-in-header img {
  height: 200px;
  margin: 30px auto;
}
/*# sourceMappingURL=SignInHeader.css.map */
.sign-in-main {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign-in-main form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 150px;
  margin-bottom: 20px;
}

.sign-in-main form input {
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
  border-bottom: 1px solid #484D6D;
  outline: none;
  color: #666;
  letter-spacing: .1em;
  font-size: 1.1em;
  text-transform: initial;
}

.sign-in-main form input::-webkit-input-placeholder {
  color: #b3b3b3;
  letter-spacing: .1em;
  font-size: 1.1em;
}

.sign-in-main form input::placeholder {
  color: #b3b3b3;
  letter-spacing: .1em;
  font-size: 1.1em;
}

.sign-in-main form button {
  margin-top: 40px;
  width: 100%;
}

.sign-in-main p {
  color: #666;
}

.sign-in-main p span {
  text-decoration: underline;
  font-weight: 700;
  text-underline-offset: .5px;
  cursor: pointer;
}
/*# sourceMappingURL=SignIn.css.map */
.dash-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 0;
}

.dash-head-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px;
  width: 85vw;
}

.dash-head-logo {
  height: 60px;
}

.dash-head-icon {
  display: none;
}

.dash-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 320px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
}

.user img {
  border-radius: 50%;
  height: 40px;
  margin-right: 10px;
}

.user p {
  text-decoration: none;
}

.sign-out {
  width: 150px;
}

.sign-out:hover {
  background-color: #EE6352;
}
/*# sourceMappingURL=DashHeader.css.map */
.categories {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #666;
  padding: 5px 0;
  transition: .1s;
  background-color: inherit;
}

.categories h1 {
  font-family: oscine, sans-serif;
  font-weight: 500;
  font-size: 1.2em;
}

.focused {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 0 10px;
  background-color: white;
}

.focused input {
  background-color: #f3f3f3;
  padding: 12px 12px;
}

.cat-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.cat-form input {
  width: 300px;
}

.cat-form input:hover {
  background-color: #f3f3f3;
}

.cat-money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
}

.cat-money p {
  color: #666;
  width: 110px;
  text-align: right;
}

.cat-money input {
  width: 110px;
  text-align: right;
}

.delete-category {
  height: 30px;
  margin-right: 10px;
}
/*# sourceMappingURL=Categories.css.map */
.groups {
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.group-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.group-titles h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.group-titles input {
  font-family: 'aglet-slab', sans-serif;
  color: #484D6D;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 20px;
}

.update-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.update-group input {
  transition: .1s;
  width: 230px;
}

.update-group input:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #f3f3f3;
  padding: 5px;
}

.group-money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
}

.group-money h2 {
  font-size: 1em;
  width: 110px;
  text-align: right;
}

.add-category {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  color: #08B2E3;
  font-weight: 700;
  cursor: pointer;
}

.add-btn {
  border-radius: 50%;
  background-color: #08B2E3;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
  font-weight: 900;
  font-size: 1.3em;
}

.delete-group {
  height: 20px;
  margin: 0 30px 20px 0;
  transition: .1s;
}

@media (max-width: 1100px) {
  .group-titles h1 {
    font-size: 1.2em;
  }
  .group-titles input {
    width: 180px;
    font-size: 1.2em;
  }
  .group-money {
    width: 210px;
  }
  .group-money h2 {
    font-size: .9em;
    width: 100px;
  }
}
/*# sourceMappingURL=Groups.css.map */
.edit-transaction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

.new-trans-info {
  background-color: #ebe6ec;
  width: 600px;
  border-radius: 5px;
}

.new-trans-info header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  background-color: #EE6352;
  border-radius: 5px 5px 0 0;
}

.new-trans-info header h2 {
  color: #ebe6ec;
  width: 230px;
}

.new-trans-info header h3 {
  width: 30px;
  position: relative;
  left: 170px;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.new-trans-info button {
  width: 90%;
  border-radius: 5px;
  background-color: #EE6352;
  margin: 20px 0 10px 0;
}

.new-trans-info button:hover {
  background-color: #ee9488;
}

.set-type {
  height: 30px;
  background-color: white;
  background-color: #ee9488;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.set-type input {
  margin: 0 7px 0 25px;
}

.trans-inputs {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 20px 0;
}

.trans-inputs input {
  height: 40px;
  margin: 5px;
  border: 1px solid #EE6352;
  border-radius: 5px;
  padding: 5px 10px;
  width: 90%;
  outline: none;
}

.trans-inputs select {
  height: 40px;
  margin: 5px;
  border: 1px solid #EE6352;
  border-radius: 5px;
  padding: 5px 10px;
  width: 90%;
  outline: none;
}

.trans-inputs textarea {
  width: 90%;
  border: 1px solid #EE6352;
  border-radius: 5px;
  padding: 5px 10px;
  height: 150px;
  margin: 5px;
  outline: none;
}

.trans-inputs #amount {
  text-align: center;
  margin-bottom: 30px;
  height: 50px;
  font-size: 1.3em;
}

.trans-inputs div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
}

.trans-inputs #date {
  width: 35%;
  margin-left: 0;
  text-transform: lowercase;
}

.trans-inputs #desc {
  width: 65%;
  margin-right: 0;
}

.remove-trans {
  font-weight: 700;
  color: #EE6352;
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
}
/*# sourceMappingURL=EditTransaction.css.map */
.expense {
  border: 1px solid #EE6352;
  height: 40px;
  width: 350px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 10px;
  margin: 10px 0;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.expense span {
  font-size: .7em;
  font-weight: 700;
  color: #666;
}

.expense-content {
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.expense-content span {
  font-size: 1.2em;
}

.income {
  border: 1px solid #08B2E3;
  height: 40px;
  width: 350px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 10px;
  margin: 10px 0;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.income span {
  font-size: .7em;
  font-weight: 700;
  color: #666;
}

.income-content {
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.income-content span {
  font-size: 1.2em;
}

@media (max-width: 1100px) {
  .income {
    width: 300px;
  }
  .expense {
    width: 300px;
  }
}
/*# sourceMappingURL=Transactions.css.map */
.add-transaction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  z-index: 1;
}

.new-trans-info {
  background-color: #ebe6ec;
  width: 600px;
  border-radius: 5px;
}

.new-trans-info header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  background-color: #EE6352;
  border-radius: 5px 5px 0 0;
}

.new-trans-info header h2 {
  color: #ebe6ec;
}

.new-trans-info header h3 {
  position: relative;
  left: 170px;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.new-trans-info button {
  width: 90%;
  border-radius: 5px;
  background-color: #EE6352;
  margin: 20px 0 30px 0;
}

.new-trans-info button:hover {
  background-color: #ee9488;
}

.set-type {
  height: 30px;
  background-color: white;
  background-color: #ee9488;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.set-type input {
  margin: 0 7px 0 25px;
}

.trans-inputs {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 20px 0;
}

.trans-inputs input {
  height: 40px;
  margin: 5px;
  border: 1px solid #EE6352;
  border-radius: 5px;
  padding: 5px 10px;
  width: 90%;
  outline: none;
}

.trans-inputs select {
  height: 40px;
  margin: 5px;
  border: 1px solid #EE6352;
  border-radius: 5px;
  padding: 5px 10px;
  width: 90%;
  outline: none;
}

.trans-inputs textarea {
  width: 90%;
  border: 1px solid #EE6352;
  border-radius: 5px;
  padding: 5px 10px;
  height: 150px;
  margin: 5px;
  outline: none;
}

.trans-inputs #amount {
  text-align: center;
  margin-bottom: 30px;
  height: 50px;
  font-size: 1.3em;
}

.trans-inputs div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
}

.trans-inputs #date {
  width: 35%;
  margin-left: 0;
}

.trans-inputs #desc {
  width: 65%;
  margin-right: 0;
}
/*# sourceMappingURL=AddTransaction.css.map */
.dash-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  height: 100vh;
  padding-top: 150px;
}

.budget {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 150px);
  width: 750px;
  margin: 0 25px;
  overflow: scroll;
  text-align: left;
  padding: 20px;
}

.trans-container {
  margin: 0 25px;
  padding: 20px;
}

.transactions {
  height: calc(100vh - 190px);
  width: 400px;
  overflow: scroll;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.trans-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
}

.add-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
}

.trans-type {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.trans-type p {
  width: 50%;
  margin-bottom: 20px;
  cursor: pointer;
}

.add-group {
  width: 100%;
  padding: 10px 0;
}

.empty {
  margin-top: 20px;
  width: 100%;
}

.trans-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.highlight-red {
  font-weight: 700;
  color: #EE6352;
  border-bottom: 2px solid #EE6352;
}

.highlight-blue {
  font-weight: 700;
  color: #08B2E3;
  border-bottom: 2px solid #08B2E3;
}

.dash-logout {
  display: none;
}

@media (max-width: 1100px) {
  .transactions {
    width: 350px;
  }
}
/*# sourceMappingURL=Dash.css.map */
.profile-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 0;
}

.profile-head-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px;
  width: 85vw;
}

.profile-head-logo {
  height: 60px;
}

.profile-head-icon {
  display: none;
}

.to-budget {
  width: 200px;
}

.to-budget:hover {
  background-color: #57A773;
}

.to-budget-mobile {
  display: none;
}

.profile-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 400px;
}

.profile-sign-out {
  width: 150px;
}

.profile-sign-out:hover {
  background-color: #EE6352;
}

.profile-logout {
  display: none;
}

@media (max-width: 900px) {
  .to-budget {
    display: none;
  }
  .profile-sign-out {
    display: none;
  }
  .profile-nav {
    justify-content: flex-end;
    width: auto;
  }
  .to-budget-mobile {
    display: initial;
    height: 40px;
    cursor: pointer;
  }
  .profile-logout {
    display: initial;
    height: 40px;
    margin-left: 50px;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .profile-header {
    height: 140px;
  }
  .profile-head-logo {
    display: none;
  }
  .profile-head-icon {
    display: initial;
    height: 70px;
  }
}
/*# sourceMappingURL=ProfileHeader.css.map */
.profile {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile button {
  margin-top: 20px;
}

.user-info {
  background-color: white;
  padding: 70px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.user-info img {
  height: 250px;
  border-radius: 50%;
  margin-bottom: 50px;
  cursor: pointer;
}

.user-info img:hover {
  border: 5px solid #08B2E3;
}

.user-info h2 {
  margin-top: 20px;
}

.email {
  text-transform: initial;
}

#edit {
  width: 170px;
  background-color: #08B2E3;
}

.profile-edit {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile-edit form {
  background-color: white;
  border-radius: 5px;
  padding: 20px 40px;
  width: 500px;
}

.profile-edit button {
  margin-top: 20px;
}

.profile-form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0;
  margin-bottom: 30px;
}

.profile-form-header h3 {
  cursor: pointer;
  position: relative;
  left: 150px;
  font-weight: 900;
  color: #666;
  font-family: sans-serif;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  height: 100px;
}

.input-container input {
  border-bottom: 1px solid #666;
  width: 96%;
  margin: 10px;
  text-transform: initial;
}

.name-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.name-input input {
  width: 50%;
}

.pic-edit {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pic-window {
  background-color: white;
  border-radius: 5px;
  padding: 20px 40px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pic-form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0;
  margin-bottom: 30px;
}

.pic-form-header h3 {
  cursor: pointer;
  position: relative;
  left: 110px;
  font-weight: 900;
  color: #666;
  font-family: sans-serif;
}

.pic-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 30px;
}

.pic-upload img {
  height: 80px;
  margin: 0 30px;
  border-radius: 50%;
}

.dropzone {
  width: 200px;
  height: 80px;
  margin: 0 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #484D6D;
  position: relative;
  border-radius: 5px;
  font-size: 1em;
}

.dropzone p {
  color: #484D6D;
}

@media (max-width: 600px) {
  .user-info {
    width: 90%;
  }
  .user-info h2 {
    font-size: 1.2em;
  }
  #edit {
    width: 90%;
    height: 70px;
    border-radius: 5px;
  }
}
/*# sourceMappingURL=Profile.css.map */
/* No CSS *//*# sourceMappingURL=Hello.css.map */
/* No CSS *//*# sourceMappingURL=IncomeIntro.css.map */
/* No CSS *//*# sourceMappingURL=IncomeEntry.css.map */
.first-name {
  font-weight: 900;
  color: #484D6D;
  text-transform: capitalize;
}
/*# sourceMappingURL=IncomeInsight.css.map */
/* No CSS *//*# sourceMappingURL=ExpensesIntro.css.map */
/* No CSS *//*# sourceMappingURL=ExpensesHousing.css.map */
#transpo-title {
  font-size: 1.6em;
}
/*# sourceMappingURL=ExpensesTransportation.css.map */
/* No CSS *//*# sourceMappingURL=ExpensesFood.css.map */
/* No CSS *//*# sourceMappingURL=ExpensesLifestyle.css.map */
/* No CSS *//*# sourceMappingURL=ExpensesInsight.css.map */
/* No CSS *//*# sourceMappingURL=GivingIntro.css.map */
/* No CSS *//*# sourceMappingURL=GivingEntry.css.map */
/* No CSS *//*# sourceMappingURL=GivingInsight.css.map */
/* No CSS *//*# sourceMappingURL=DebtIntro.css.map */
/* No CSS *//*# sourceMappingURL=DebtEntry.css.map */
#debt {
  font-size: 2.2em;
}
/*# sourceMappingURL=DebtInsight.css.map */
/* No CSS *//*# sourceMappingURL=FinalInsight.css.map */
.continue {
  width: 200px;
}

p {
  color: #666;
  line-height: 1.5em;
}

.intro {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-animation: flyInDown 1s ease;
          animation: flyInDown 1s ease;
}

.intro-content {
  width: 500px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.intro-content h1 {
  font-size: 2.5em;
}

.loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: #484D6D;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.entry {
  width: 550px;
}

.entry h1 {
  font-size: 2.5em;
}

.entry p {
  font-size: 1em;
}

.entry-form {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}

.entry-headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 20px;
}

.entry-headers h1 {
  font-size: 2em;
}

.entry-money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.entry-money p {
  width: 150px;
  text-align: right;
}

.entry-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #484D6D;
  margin-bottom: 15px;
  transition: .1s;
}

.entry-line input {
  width: 250px;
}

.entry-line input:hover {
  background-color: #f3f3f3;
}

.entry-line input:focus {
  background-color: #f3f3f3;
}

.entry-money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.entry-money input {
  text-align: right;
  width: 80px;
}

.entry-money p {
  width: 150px;
  text-align: right;
}

.go-back {
  color: #666;
  opacity: .5;
  margin-top: 15px;
}

@-webkit-keyframes flyInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes flyInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .entry {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .entry-form {
    width: 90%;
  }
  .continue {
    width: 90%;
  }
}
/*# sourceMappingURL=Welcome.css.map */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.App {
  text-align: center;
  font-family: oscine, sans-serif;
  letter-spacing: .1em;
  background-color: #ebe6ec;
  text-transform: capitalize;
}

button {
  border: none;
  color: #fff;
  background-color: #484D6D;
  font-family: oscine, sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  height: 40px;
  width: 100px;
  letter-spacing: .12em;
  outline: none;
  transition: .2s;
}

button:hover {
  background-color: #63667F;
}

h1 {
  color: #484D6D;
  font-family: aglet-slab, sans-serif;
  font-weight: 700;
  font-size: 3em;
}

h2 {
  color: #484D6D;
  font-size: 1.5em;
  font-weight: 700;
}

p {
  text-transform: initial;
}

input {
  text-transform: capitalize;
  color: #666;
  letter-spacing: .1em;
  outline: none;
}

.focused {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 0 10px;
  background-color: white;
}

.focused input {
  background-color: #f3f3f3;
  padding: 12px 12px;
}

.hidden {
  display: none;
}

.blank {
  visibility: hidden;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes flyInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes flyInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes flyInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes flyInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes flyInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes flyInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
/*# sourceMappingURL=App.css.map */
